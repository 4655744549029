import ApiService from "@/services/Api";
import Vue from "vue";
import {
  showErrorMessage
} from "@/utils/showError";

const getDefaultState = () => {
  return {
    roles: [],
    permissions: [],
  };
};
// var permission;
const state = getDefaultState();

//getters types
const getters = {
  getRoles(state) {
    return state.roles;
  },
  getRolesWithOnlyName(state) {
    return state.roles.map((role) => role.name);
  },
  getUserPermissions(state) {
    return state.permissions;
  },
};

const mutations = {
  /**
   *
   * @param {*} state Updating state with logged In user name and deo_id
   * @param {*} data  Getting data in params and updating state values
   */
  GET_ROLES(state, roles) {
    state.roles = roles;
  },
  GET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  ADD_ROLE(state, newRole) {
    state.roles.unshift(newRole);
  },
  DELETE_ROLE(state, userId) {
    const foundIndex = state.roles.findIndex((role) => role.id === userId);
    if (foundIndex > -1) state.roles.splice(foundIndex, 1);
  },
  UPDATE_ROLE(state, updatedRole) {
    const foundIndex = state.roles.findIndex(
      (role) => role.id === updatedRole.id
    );
    if (foundIndex > -1) Vue.set(state.roles, foundIndex, updatedRole);
  },
};

const actions = {
  GET_ROLES({ commit }) {
    return ApiService.GetRequest("merchant/role")
      .then((response) => {
        if (Array.isArray(response.data.result.data))
          commit("GET_ROLES", response.data.result.data);
        return response;
      })
      .catch((err) => {
        showErrorMessage(err);
        throw err;
      });
  },
  GET_PERMISSIONS({ commit }) {
    return ApiService.GetRequest("merchant/permission")
      .then((response) => {
        if (Array.isArray(response.data.result.data)) {
          commit("GET_PERMISSIONS", response.data.result.data);
        }
        return response;
      })
      .catch((err) => {
        showErrorMessage(err);
        throw err;
      });
  },
  ADD_ROLE({ commit }, payload) {
    return ApiService.PostRequest("merchant/add-role", payload)
      .then((response) => {
        commit("ADD_ROLE", response.data.result.data);
        return response.data;
      })
      .catch((err) => {
        showErrorMessage(err);
        throw err;
      });
  },
  DELETE_ROLE({ commit }, roleId) {
    return ApiService.PostRequest(`merchant/role-delete/${roleId}`)
      .then((response) => {
        commit("DELETE_ROLE", roleId);
        return response;
      })
      .catch((err) => {
        showErrorMessage(err);
        throw err;
      });
  },
  UPDATE_ROLE({ commit }, payload) {
    var Name = payload.data.name;
    return ApiService.PostRequest(`merchant/role-update/${payload.id}`, {
      name: Name,
      permission: payload.data.permission,
    })
      .then((response) => {
        commit("UPDATE_ROLE", response.data.result.data);
        return response.data;
      })
      .catch((err) => {
        showErrorMessage(err);
        throw err;
      });
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
