import Vue from "vue";
import loadConfig from "./config/autoload";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import css from "./assets/css/style.css";
import store from "./store";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import hasPermission from "@/services/Permissions.js";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
import * as Sentry from "@sentry/vue";
// date format
import format from "date-fns/format";
import moment from "moment/moment";
import "moment-timezone";
/**
 * High Charts
 */
Maps(Highcharts);
Vue.use(VueSweetalert2);
Vue.use(VueFileAgent);
Vue.use(HighchartsVue);
Vue.use(hasPermission);
/**
 * Load config.json file before creating the new Vue app
 */
loadConfig(process.env.BASE_URL + "static/config.json").then(async (config) => {
  window.appConfig = config;

  // add these app configs
  Vue.use({
    install(VueConstructor) {
      VueConstructor.prototype.$config = config;
    },
  });

  Vue.config.productionTip = false;

  const VueTelInputOptions = {
    inputOptions: {
      required: true,
      type: "tel",
    },
    dropdownOptions: {
      showDialCodeInSelection: true,
      showSearchBox: true,
      width: "280px",
    },
    invalidMsg: "Invalid Phone Number",
  };
  Vue.use(VueTelInput, VueTelInputOptions);

  if (window.location.hostname === "portal.facia.ai") {
    // Sentry Implementation
    Sentry.init({
      Vue,
      dsn: "https://704de88f628e9e0fc4273a92615a5b1e@o4506297293471744.ingest.sentry.io/4506347698847744",
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      // Performance Monitoring
      tracesSampleRate: 0.3, // Capture 100% of the transactions, reduce in production!
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  Vue.filter("convertUTCtoLocalTime", function (date) {
    try {
      date = new Date(date);
      return format(date, "yyyy-MM-dd - hh:mm:ss a");
    } catch (err) {
      return err;
    }
  });

  Vue.filter("convertUTCtoLocalTimeOnly", function (date) {
    try {
      date = new Date(date);
      return format(date, "hh:mm:ss a");
    } catch (err) {
      return date;
    }
  });

  Vue.filter("dateTimeConversionClientTimeZone", function (date) {
    try {
      const userTimeZone = moment.tz.guess();
      const momentDate = moment.utc(date); // Parse date as UTC
      const convertedDate = momentDate
        .tz(userTimeZone)
        .format("YYYY-MM-DD hh:mm:ss A");
      if (convertedDate === "Invalid date") {
        return "--";
      }
      return convertedDate;
    } catch (err) {
      return date;
    }
  });

  Vue.filter("timeConversionClientTimeZone", function (date) {
    try {
      const momentDate = moment(date);
      // Intl.DateTimeFormat().resolvedOptions().timeZone
      const convertedDate = momentDate
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("HH:mm:ss A");
      if (convertedDate === "Invalid date") {
        return "--";
      }
      return convertedDate;
    } catch (err) {
      return date;
    }
  });

  new Vue({
    router,
    vuetify,
    css,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
