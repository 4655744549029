const APP_DEBUG_ENABLED = process.env.NODE_ENV === 'development';

const loadConfigs = (configPath) => {
    const fetchOptions = {
        method: 'GET',
    };

    return new Promise((resolve, reject) => {
        fetch(configPath, fetchOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Auto-loading config.json failed. HTTP error code: ${response.status}. Error message: ${response.statusText}`);
                }
                resolve(response.json());
            })
            .catch((error) => {
                // fetching app configurations failed
                if (APP_DEBUG_ENABLED) {
                    console.log({ autoloadConfigsError: error }); // eslint-disable-line no-console
                }
                reject(error);
            });
    });
};

export default loadConfigs;
