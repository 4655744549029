import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme:{
        themes:{
            light:{
                primary: "#173dd0",
                plainWhite: "#FFFFFF",
                appNavBar: "#E5E5E5",
                greyColor: "#292B30",
                appBackGround: "#FAFAFA",
                navUser: "#11142D",
                navDropDown: "#727A89",
                navAvatar: "#EBF4F4",
                appSideBar: "#F8F8F8",
                textColor: "#FFFFFF",
                chipText: "#C8A135",
                chipBackground: "#FAF7EC",
                secondary: "#777F8F",
                dividerColor: "#E8EBF6",
                cancelBtnText: "#5C5D63",
                darkGrey: "#a6b0b0",
                declineColor: "#FF784B",
                offWhiteColor: '#f5f5f5'
            }
        }
    }
});
