export const sidebarItems = [
  {
    main: [
      [
        "view-dashboard-merchant",
        {
          title: "Dashboard",
          img: require("@/assets/icons/dashboard.svg"),
          attrs: {
            to: "/dashboard",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-transactions-merchant",
        {
          title: "Transactions",
          img: require("@/assets/icons/transaction-reports.svg"),
          attrs: {
            to: "/transactions",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
    ],
  },
  {
    Tracking: [
      [
        "view-student-listing",
        {
          title: "Students",
          img: require("@/assets/icons/transaction-reports.svg"),
          attrs: {
            to: "/student",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-student-listing",
        {
          title: "Exams",
          img: require("@/assets/icons/transaction-reports.svg"),
          attrs: {
            to: "/exam",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-student-listing",
        {
          title: "Lectures",
          img: require("@/assets/icons/transaction-reports.svg"),
          attrs: {
            to: "/lecture",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
    ]
  },
  {
    Services: [
      [
        "perform-demo",
        {
          title: "Liveness Detection",
          img: require("@/assets/icons/liveness-demo.svg"),
          attrs: {
            to: "/demo",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-one-to-one-demo",
        {
          title: "Face Match",
          img: require("@/assets/icons/faceMatch-1-1.svg"),
          attrs: {
            to: "/face-match",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-one-to-many-demo",
        {
          title: "Face Search",
          img: require("@/assets/icons/one-to-many.svg"),
          attrs: {
            to: "/face-search",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-age-estimation",
        {
          title: "Age Estimation",
          img: require("@/assets/icons/age-verification.svg"),
          attrs: {
            to: "/age-estimation",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
      [
        "view-document-ocr",
        {
          title: "Document OCR",
          img: require("@/assets/icons/ocr.png"),
          attrs: {
            to: "/document-ocr",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
    ],
  },
  {
    Enrollment: [
      [
        "view-face-enrollment",
        {
          title: "Face Enroll",
          img: require("@/assets/icons/face-enroll.svg"),
          attrs: {
            to: "/face-enroll",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
    ]
  },
  {
    Billing: [
      [
        "view-billing-status",
        {
          title: "Billing",
          img: require("@/assets/icons/billing.png"),
          attrs: {
            to: "/billing",
            "active-class": "primary--text list-item-active",
          },
        },
      ],
    ]
  },
  {
    API_Documentation: [
      [
        "view-dashboard-merchant",
        {
          title: "Web API Docs",
          img: require("../assets/icons/web.svg"),
          attrs: {
            target: "_blank",
            href: "https://developers.facia.ai/",
          },
        },
      ],
      [
        "view-dashboard-merchant",
        {
          title: "Android SDK Docs",
          img: require("../assets/icons/android.svg"),
          attrs: {
            target: "_blank",
            href: "https://developers.facia.ai/mobile/sdks/platforms/android-sdk",
          },
        },
      ],
      [
        "view-dashboard-merchant",
        {
          title: "iOS SDK Docs",
          img: require("../assets/icons/ios.svg"),
          attrs: {
            target: "_blank",
            href: "https://developers.facia.ai/mobile/sdks/platforms/ios-sdk",
          },
        },
      ],
    ],
  },
];
