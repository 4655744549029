const getDefaultState = () => {
  return {
    sideBar: null,
    dashboardRoute: true,
    userName: "",
    userImage: null,
    userEmail: "",

    userRole: "",
    userPermissions: [],
    firstRoute: "/",
    title: "",
    deviceDetails: {
      ip: null,
      platform: null,
      browser: null,
      userAgent: null,
      deviceName: null,
      deviceType: null,
      resolution: null,
      continent: null,
      continentCode: null,
      latitude: null,
      longitude: null,
      isp: null,
      rdns: null,
      countryName: null,
      countryCode: null,
    },
  };
};

const state = getDefaultState();

const getters = {
  getSideBarItems(state) {
    return { dashboard: state.dashboardRoute, items: state.sideBar };
  },

  getPermissions(state) {
    return state.userPermissions;
  },

  getFirstRoute(state) {
    return state.firstRoute;
  },
  userEmailGetter(state) {
    return state.userEmail;
  },
  userNameGetter(state) {
    return state.userName;
  },
  userImageGetter(state) {
    return state.userImage;
  },
  userLoggedIn(state) {
    return state.loggedIn;
  },
  merchantRole(state) {
    return state.userRole;
  },
  deviceDetails(state) {
    return state.deviceDetails;
  },
};

const mutations = {
  SET_SIDEBAR_ITEM(state, data) {
    state.dashboardRoute = data.dashboard;
    state.sideBar = data.items;
  },

  LOGIN_SUCCESS(state, payload) {
    state.userName = payload.first_name + " " + payload.last_name;
    state.userImage = payload.avatar;
    state.userEmail = payload.email;
    let allUserPermissions = payload.roles[0].permissions;
    if (payload.roles.length > 1) {
      allUserPermissions.push(payload.roles[1].permissions[0]);
    }
    state.userPermissions = allUserPermissions;
  },

  SET_FIRST_ROUTE(state, data) {
    state.firstRoute = data.to;
    state.title = data.title;
  },
  UPDATE_PROFILE(state, payload) {
    state.userImage = payload;
  },
  update_userName(state, payload) {
    state.userName = payload;
  },
  UPDATE_CLIENT_SECRET(state, payload) {
    state.mer = payload;
  },
  SET_DEVICE_DETAILS(state, deviceDetails) {
    state.deviceDetails.ip = deviceDetails.query;
    state.deviceDetails.continent = deviceDetails.continent;
    state.deviceDetails.continentCode = deviceDetails.continentCode;
    state.deviceDetails.latitude = deviceDetails.lat;
    state.deviceDetails.longitude = deviceDetails.lon;
    state.deviceDetails.isp = deviceDetails.isp;
    if (deviceDetails && deviceDetails.query) {
      state.deviceDetails.rdns =
        deviceDetails.query.split("").reverse().join("") + ".in-addr.arpa";
    }
    (state.deviceDetails.countryName = deviceDetails.country),
      (state.deviceDetails.countryCode = deviceDetails.countryCode);
  },
  SET_LOGOUT(state) {
    Object.assign(state, getDefaultState());
  },
};
const actions = {
  SET_SIDEBAR_ITEM({ commit }, payload) {
    commit("SET_SIDEBAR_ITEM", payload);
  },
  SET_FIRST_ROUTE({ commit }, data) {
    commit("SET_FIRST_ROUTE", data);
  },

  LOGIN({ commit }, userObject) {
    commit("LOGIN_SUCCESS", userObject);
  },
  UPDATE_PROFILE({ commit }, payload) {
    commit("UPDATE_PROFILE", payload.userImage);
    commit("update_userName", payload.userName);
  },
  updateUserName({ commit }, payload) {
    commit("update_userName", payload);
  },
  UPDATE_CLIENT_SECRET({ commit }, payload) {
    commit("UPDATE_CLIENT_SECRET", payload);
  },
  SET_LOGOUT({ commit }) {
    commit("SET_LOGOUT");
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
