import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "../store";
import router from "../router"
var locations = {};
function setConfig() {
  const appConfig = window.appConfig;
  if (appConfig && appConfig.locations) {
    locations = appConfig.locations;
  }
  axios.defaults.baseURL = locations.VUE_APP_API_URL;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch("logout");
      store.dispatch("SET_LOGOUT");
      router.push("/");
    }
    throw error;
  }
);

const ApiService = {
  /**
  * Initialize Axios configuration.
  */
  init() {
    setConfig();
    Vue.use(VueAxios, axios);
    axios.defaults.baseURL = locations.VUE_APP_API_URL; //current
    axios.defaults.headers.common["Content-Type"] = "application/json";
  },

  /**
   * Make a POST request.
   * @param {string} url - The endpoint URL.
   * @param {object} data - The data to be sent in the request body.
   * @param {string} [baseUrl="merchant"] - The base URL type (either "merchant" or "iframe").
   * @param {string} [client_secret=""] - The client secret for iframe requests.
   * @returns {Promise} - The Axios promise for the HTTP request.
   */
  PostRequest(url, data, baseUrl = "merchant", client_secret = "") {
    setConfig();
    if (baseUrl === "iframe") {
      axios.defaults.baseURL = locations.VUE_APP_IFRAME_URL;
    } else {
      axios.defaults.baseURL = locations.VUE_APP_API_URL;
    }
    return axios({
      method: "POST",
      url: url,
      data: data,
      headers:
        baseUrl === "iframe"
          ? {
            "client-secret": "" + client_secret,
            ip: store.getters["deviceDetails"].ip,
          }
          : window.location.hostname === "localhost"
            ? {
              Authorization: "Bearer " + localStorage.getItem("token"),
            }
            : { credentials: "include", withCredentials: true },
    }).then((response) => response)
  },

  /**
   * Make a GET request.
   * @param {string} url - The endpoint URL.
   * @param {object} data - The query parameters to be sent.
   * @param {string} [baseUrl="merchant"] - The base URL type (either "merchant" or "iframe").
   * @returns {Promise} - The Axios promise for the HTTP request.
   */
  GetRequest(url, data, baseUrl = "merchant") {
    setConfig();

    if (baseUrl === "iframe") {
      axios.defaults.baseURL = locations.VUE_APP_IFRAME_URL;
    } else {
      axios.defaults.baseURL = locations.VUE_APP_API_URL;
    }
    return axios({
      method: "GET",
      url: url,
      params: data,
      headers:
        window.location.hostname === "localhost"
          ? { Authorization: "Bearer " + localStorage.getItem("token") }
          : { credentials: "include", withCredentials: true },
    }).then((response) => response)
  },

  /**
  * Make a DELETE request.
  * @param {string} url - The endpoint URL.
  * @returns {Promise} - The Axios promise for the HTTP request.
  */
  DeleteRequest(url) {
    setConfig();
    return axios({
      method: "DELETE",
      url: url,
      headers:
        window.location.hostname === "localhost"
          ? { Authorization: "Bearer " + localStorage.getItem("token") }
          : { credentials: "include" },
    }).then((response) => response)
  },

  /**
   * Make a PUT request.
   * @param {string} url - The endpoint URL.
   * @returns {Promise} - The Axios promise for the HTTP request.
   */
  PutRequest(url) {
    setConfig();
    return axios({
      method: "PUT",
      url: url,
      headers:
        window.location.hostname === "localhost"
          ? { Authorization: "Bearer " + localStorage.getItem("token") }
          : { credentials: "include" },
    }).then((response) => response)
  },
};

export default ApiService;
