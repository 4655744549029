<template>
  <v-snackbar
    v-if="showSnackbar"
    v-model="showSnackbar"
    top
    right
    :elevation="0"
    color="primary"
    class="snack"
    height="54"
    :class="'mt-1 snack-type-' + type"
    :timeout="indeterminate ? '-1' : '2500'"
  >
    <div class="notification-content">
      <div :class="'notification-icon-' + type">
        <v-icon v-if="type == 'success'">mdi-check-circle</v-icon>
        <v-icon
          class="ma-1"
          size="28"
          v-if="type == 'error' || type == 'warning'"
          >mdi-close-circle</v-icon
        >
        <v-icon v-if="type == 'info'">mdi-information</v-icon>
      </div>

      <div class="notification-message text-capitalize">
        {{ message }}
      </div>
    </div>
    <template v-slot:action="{ attrs }">
      <a class="close-notification-button" v-bind="attrs" @click="changeValue">
        <v-icon size="20" color="black">mdi-close</v-icon>
      </a>
    </template>
  </v-snackbar>
</template>

<script>
import EventBus from "@/js/eventBus.js";
export default {
  name: "snackbar",
  props: ["vmodel", "errorMessage"],
  data() {
    return {
      showSnackbar: false,
      message: "",
      type: "info",
      indeterminate: false,
    };
  },
  mounted() {
    EventBus.$on("show-notification-bar", (data) => {
      this.type = data.type;
      this.message = data.message;
      this.showSnackbar = true;
      this.indeterminate = data.indeterminate ? true : false;
    });
  },
  methods: {
    /**
     * Changes the value of showSnackbar to false, hiding the snackbar.
     *
     * @method changeValue
     */
    changeValue() {
      this.showSnackbar = false;
    },
  },
};
</script>

<style>
.notification-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 7px;
}
.notification-icon-success {
  background-color: #249da8;
  border-radius: 10px !important;
  margin-right: 6px !important;
}

.notification-icon-error {
  background-color: #ff784b;
  border-radius: 10px !important;
  margin-right: 6px !important;
}

.notification-icon-info {
  background-color: #016de6;
  border-radius: 10px !important;
  margin-right: 6px !important;
}

.notification-icon-warning {
  background-color: #ee9401;
  border-radius: 10px !important;
  margin-right: 6px !important;
}
.notification-icon i {
  width: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-icon {
  font-size: 25px !important;
  padding: 5px;
  border-radius: 8px;
  margin-right: 10px;
}
.notification-message {
  color: #484848 !important;
}
.v-snack__btn.close-notification-button {
  color: #484848;
  margin-right: 10px;
}

.v-snack__content {
  padding: 5px 7px !important;
}

.v-snack:not(.v-snack--absolute) {
  height: auto !important;
}
.v-snack {
  z-index: 1000000 !important;
}
.v-sheet.v-snack__wrapper {
  border-radius: 10px !important;
  margin: 15px !important;
  min-height: 45px !important;
}

.v-snack.snack-type-success .v-snack__wrapper {
  background-color: #edf4f5 !important;
  border: 0.6px solid #e3eaeb;
  border-radius: 7px;
}

.v-snack.snack-type-error .v-snack__wrapper {
  background-color: #faf2f0 !important;
  border: 0.6px solid #efe7e5;
  border-radius: 7px;
}

.v-snack.snack-type-info .v-snack__wrapper {
  background-color: #e4effa !important;
  border: 1px solid #abcdf1 !important;
}
.v-snack.snack-type-warning .v-snack__wrapper {
  background-color: #fef7e9 !important;
  border: 1px solid #fde0af !important;
}

.v-snack.snack-type-info .v-snack__wrapper {
  background-color: #e4effa !important;
  border: 1px solid #abcdf1 !important;
}

.v-snack.snack-type-warning .v-snack__wrapper {
  background-color: #fef7e9 !important;
  border: 1px solid #fde0af !important;
}
</style>
